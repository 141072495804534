@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @apply transition-colors duration-200; /* add transition to everything when switiching between light and dark mode */
  @apply bg-background;
  @apply dark:bg-background-dark;
  @apply tc;
  overflow-y: scroll;
  font-size: 18px; /* I wanted the default font size to be slightly larger */
}

.cc { /* custom colour */
  @apply transition-colors duration-200; /* add transition to everything with custom colours */
}

.tc { /* text colour */
  @apply transition-colors duration-200; /* add transition to colours */
  @apply text-text dark:text-text-dark /* add default text colours */
}

.list-sublist {
  list-style-type: circle;
}


/* Used for segmented button, can't really be moved out easily */
.segmented-button-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;
  --highlight-height: auto;
  --highlight-y-pos: 0;
}

.segmented-button-ready::before {
  transition: transform 0.3s ease, width 0.3s ease;
}

/* scrollbar styling */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}
  
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}
  
::-webkit-scrollbar-thumb {
  border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
  background-clip: padding-box;  
}
  
::-webkit-scrollbar-thumb:hover {
    background: #555e;
}